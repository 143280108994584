import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import { mask } from "vue-the-mask";
import Validation from "@/helpers/Validation";
import EventModule from "@/store/module/shared/EventModule";
import ApproveDialog from "../../../shared/Approve.vue";
import Waiter from "@/helpers/Waiter";
let AccreditationDialog = class AccreditationDialog extends Vue {
    constructor() {
        super(...arguments);
        this.approve = {
            download: false,
        };
        this.firstBackground = {
            ref: "firstBackground",
            type: "fileupload",
            icon: "mdi-camera",
            placeholder: "Первый фон",
            value: "",
            accept: "image/*",
            onChange: async (file) => {
                const fd = new FormData();
                fd.append("image", file);
                const response = await this.$store.dispatch(EventModule.types.actions.UPLOAD_AVATAR, { fd });
                this.firstBackground.value = response.filename;
            },
            rules: [Validation.required],
            showOnlyForSuperAuth: false,
        };
        this.secondBackground = {
            ref: "secondBackground",
            type: "fileupload",
            icon: "mdi-camera",
            placeholder: "Второй фон",
            value: "",
            accept: "image/*",
            onChange: async (file) => {
                if (file === null)
                    return;
                const fd = new FormData();
                fd.append("image", file);
                const response = await this.$store.dispatch(EventModule.types.actions.UPLOAD_AVATAR, { fd });
                this.secondBackground.value = response.filename;
            },
            rules: [],
            showOnlyForSuperAuth: false,
        };
        // avatar
        this.avatarPositionX = {
            ref: "avatarPositionX",
            type: "slider",
            min: 0,
            max: 800,
            value: 30,
            isY: false,
            label: "Ось X",
            showOnlyForSuperAuth: false,
        };
        this.avatarPositionY = {
            ref: "avatarPositionY",
            type: "slider",
            min: 0,
            max: 900,
            value: 200,
            isY: true,
            label: "Ocь Y",
            showOnlyForSuperAuth: false,
        };
        this.avatarWidth = {
            ref: "avatarWidth",
            type: "input",
            label: "Ширина фото",
            placeholder: "300",
            value: "120",
            showOnlyForSuperAuth: false,
        };
        this.avatarHeight = {
            ref: "avatarHeight",
            type: "input",
            label: "Высота фото",
            placeholder: "300",
            value: "154",
            showOnlyForSuperAuth: false,
        };
        this.avatarGroup = {
            title: "Фото",
            left: [this.avatarPositionY],
            right: [this.avatarPositionX, this.avatarWidth, this.avatarHeight],
        };
        // name
        this.namePositionX = {
            ref: "namePositionX",
            type: "slider",
            min: 0,
            max: 800,
            value: 170,
            isY: false,
            label: "Ось X",
            showOnlyForSuperAuth: false,
        };
        this.namePositionY = {
            ref: "namePositionY",
            type: "slider",
            min: 0,
            max: 900,
            value: 250,
            isY: true,
            label: "Ocь Y",
            showOnlyForSuperAuth: false,
        };
        this.nameFontSize = {
            ref: "nameFontSize",
            type: "input",
            label: "Размер шрифта",
            placeholder: "22",
            value: "22",
            showOnlyForSuperAuth: false,
        };
        this.nameColor = {
            ref: "nameColor",
            type: "colorpicker",
            value: "#000000",
            mask: "!#XXXXXXXX",
            menu: false,
            label: "Цвет текста",
            showOnlyForSuperAuth: false,
        };
        this.nameGroup = {
            title: "Имя",
            left: [this.namePositionY],
            right: [this.namePositionX, this.nameFontSize, this.nameColor],
        };
        //surname
        this.surnamePositionX = {
            ref: "surnamePositionX",
            type: "slider",
            min: 0,
            max: 800,
            value: 170,
            isY: false,
            label: "Ось X",
            showOnlyForSuperAuth: false,
        };
        this.surnamePositionY = {
            ref: "surnamePositionY",
            type: "slider",
            min: 0,
            max: 900,
            value: 210,
            isY: true,
            label: "Ocь Y",
            showOnlyForSuperAuth: false,
        };
        this.surnameFontSize = {
            ref: "surnameFontSize",
            type: "input",
            label: "Размер шрифта",
            placeholder: "22",
            value: "22",
            showOnlyForSuperAuth: false,
        };
        this.surnameColor = {
            ref: "surnameColor",
            type: "colorpicker",
            value: "#000000",
            mask: "!#XXXXXXXX",
            menu: false,
            label: "Цвет текста",
            showOnlyForSuperAuth: false,
        };
        this.surnnameGroup = {
            title: "Фамилия",
            left: [this.surnamePositionY],
            right: [this.surnamePositionX, this.surnameFontSize, this.surnameColor],
        };
        // address
        this.addressPositionX = {
            ref: "addressPositionX",
            type: "slider",
            min: 0,
            max: 800,
            value: 170,
            isY: false,
            label: "Ось X",
            showOnlyForSuperAuth: false,
        };
        this.addressPositionY = {
            ref: "addressPositionY",
            type: "slider",
            min: 0,
            max: 900,
            value: 290,
            isY: true,
            label: "Ocь Y",
            showOnlyForSuperAuth: false,
        };
        this.addressFontSize = {
            ref: "addressFontSize",
            type: "input",
            label: "Размер шрифта",
            placeholder: "22",
            value: "22",
            showOnlyForSuperAuth: false,
        };
        this.addressColor = {
            ref: "addressColor",
            type: "colorpicker",
            value: "#000000",
            mask: "!#XXXXXXXX",
            menu: false,
            label: "Цвет текста",
            showOnlyForSuperAuth: false,
        };
        this.addressGroup = {
            title: "Адрес",
            left: [this.addressPositionY],
            right: [this.addressPositionX, this.addressFontSize, this.addressColor],
        };
        // amplua
        this.ampluaPositionX = {
            ref: "ampluaPositionX",
            type: "slider",
            min: 0,
            max: 800,
            value: 30,
            isY: false,
            label: "Ось X",
            showOnlyForSuperAuth: false,
        };
        this.ampluaPositionY = {
            ref: "ampluaPositionY",
            type: "slider",
            min: 0,
            max: 900,
            value: 380,
            isY: true,
            label: "Ocь Y",
            showOnlyForSuperAuth: false,
        };
        this.ampluaFontSize = {
            ref: "ampluaFontSize",
            type: "input",
            label: "Размер шрифта",
            placeholder: "22",
            value: "22",
            showOnlyForSuperAuth: false,
        };
        this.ampluaColor = {
            ref: "ampluaColor",
            type: "colorpicker",
            value: "#000000",
            mask: "!#XXXXXXXX",
            menu: false,
            label: "Цвет текста",
            showOnlyForSuperAuth: false,
        };
        this.ampluaGroup = {
            title: "Амплуа",
            left: [this.ampluaPositionY],
            right: [this.ampluaPositionX, this.ampluaFontSize, this.ampluaColor],
        };
        this.constructorGroups = [
            this.avatarGroup,
            this.nameGroup,
            this.surnnameGroup,
            this.addressGroup,
            this.ampluaGroup,
        ];
        this.pageWidth = {
            ref: "pageWidth",
            type: "input",
            value: "94",
            label: "Ширина страницы (милиметры)",
            placeholder: "210",
            showOnlyForSuperAuth: false,
        };
        this.pageHeight = {
            ref: "pageHeight",
            type: "input",
            value: "134",
            label: "Высота страницы (милиметры)",
            placeholder: "297",
            showOnlyForSuperAuth: false,
        };
        this.htmlZoom = {
            ref: "htmlZoom",
            type: "input",
            value: "0.75",
            label: "Масштаб страницы",
            placeholder: "0.75",
            showOnlyForSuperAuth: false,
        };
        this.pageGroup = [this.htmlZoom, this.pageWidth, this.pageHeight];
    }
    get showDialog() {
        return this.value;
    }
    set showDialog(value) {
        this.$emit("input", value);
    }
    swatchStyle(colorPicker) {
        return {
            backgroundColor: colorPicker.value,
            cursor: "pointer",
            height: "30px",
            width: "30px",
            border: "1px solid black",
            borderRadius: colorPicker.menu ? "50%" : "4px",
            transition: "border-radius 200ms ease-in-out",
        };
    }
    get defaultUserAvatar() {
        return `${IMAGE_SERVER_URL}/public/user-placeholder-male.jpg`;
    }
    get firstBackgroundStyle() {
        return `background: ${this.firstBackground.value === "" ? "#29B6F6" : `url(${IMAGE_SERVER_URL}/public/${this.firstBackground.value})`} center center no-repeat; ${this.pageStyle}`;
    }
    // get firstBackgroundStyle() {
    //   return `background: ${
    //     this.firstBackground.value === "" ? "#29B6F6" : `url('${this.firstBackground.value}')`
    //   } center center no-repeat; ${this.pageStyle}`
    // }
    get secondBackgroundStyle() {
        return `background: ${this.firstBackground.value === "" ? "#29B6F6" : `url(${IMAGE_SERVER_URL}/public/${this.secondBackground.value})`} center center no-repeat; ${this.pageStyle}`;
    }
    get avatarStyle() {
        return `top: ${this.avatarPositionY.value}px; left: ${this.avatarPositionX.value}px; width: ${this.avatarWidth.value}px; height: ${this.avatarHeight.value}px;`;
    }
    get nameStyles() {
        return `top: ${this.namePositionY.value}px; left: ${this.namePositionX.value}px; font-size: ${this.nameFontSize.value}px; color: ${this.nameColor.value};`;
    }
    get surnameStyles() {
        return `top: ${this.surnamePositionY.value}px; left: ${this.surnamePositionX.value}px; font-size: ${this.surnameFontSize.value}px; color: ${this.surnameColor.value}`;
    }
    get addressStyles() {
        return `top: ${this.addressPositionY.value}px; left: ${this.addressPositionX.value}px; font-size: ${this.addressFontSize.value}px; color: ${this.addressColor.value}`;
    }
    get ampluaStyles() {
        return `top: ${this.ampluaPositionY.value}px; left: ${this.ampluaPositionX.value}px; font-size: ${this.ampluaFontSize.value}px; color: ${this.ampluaColor.value}`;
    }
    get pageStyle() {
        return `width: ${this.pageWidth.value}mm; height: ${this.pageHeight.value}mm;`;
    }
    get htmlStyle() {
        return `zoom: ${this.htmlZoom.value};`;
    }
    getBase64(file, onResult) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            onResult(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }
    async checkDownloading() {
        const taskId = this.$store.getters[EventModule.types.getters.ORDERED_ACCREDITATION_TASK_ID];
        const downloadStatus = await this.$store.dispatch(EventModule.types.actions.DOWNLOAD_ACCREDITATION, {
            taskId,
            eventName: this.event.name,
        });
        if (downloadStatus === true) {
            this.$notify({
                type: "success",
                title: "Выгрузка аккредитации прошла успешно",
            });
            return;
        }
        this.$notify({
            type: "primary",
            title: "Ожидаем формирования файла...",
            duration: 2000,
        });
        await Waiter.wait(4000);
        return this.checkDownloading();
    }
    async download() {
        const data = {
            firstBackgroundStyle: this.firstBackgroundStyle,
            secondBackgroundStyle: this.secondBackground.value === "" ? null : this.secondBackgroundStyle,
            avatarStyle: this.avatarStyle,
            nameStyle: this.nameStyles,
            surnameStyle: this.surnameStyles,
            addressStyle: this.addressStyles,
            ampluaStyle: this.ampluaStyles,
            pageWidth: this.pageWidth.value,
            pageHeight: this.pageHeight.value,
            htmlStyle: this.htmlStyle,
            eventId: this.event.id,
            filters: this.filters,
        };
        this.$notify({
            type: "primary",
            title: "Формируем задачу на выгрузку",
            duration: 3000,
        });
        await this.$store.dispatch(EventModule.types.actions.CREATE_ACCREDITATION, { template: data });
        await this.checkDownloading();
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], AccreditationDialog.prototype, "value", void 0);
__decorate([
    Prop({ type: Object, default: null })
], AccreditationDialog.prototype, "event", void 0);
__decorate([
    Prop({ type: Object, default: { ampluaId: null, categoryId: null } })
], AccreditationDialog.prototype, "filters", void 0);
AccreditationDialog = __decorate([
    Component({
        components: {
            ApproveDialog,
        },
        directives: {
            mask,
        },
    })
], AccreditationDialog);
export default AccreditationDialog;
