import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Validation from "@/helpers/Validation";
import EventModule from "@/store/module/shared/EventModule";
import UsersModule from "@/store/module/admin/UsersModule";
import AdminModule from "@/store/module/admin/AdminModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import UsersTable from "../../users/UsersTable.vue";
import ApproveDialog from "../../../shared/Approve.vue";
let EventParticipants = class EventParticipants extends Vue {
    constructor() {
        super(...arguments);
        this.approve = {
            add: false,
        };
        this.pickedEventCategory = null;
        this.clearSelected = false;
        this.selectedUsers = [];
        this.nonParticipants = {
            nonParticipants: [],
        };
        this.amplua = {
            ref: "amplua",
            type: "select",
            label: "Амплуа",
            value: null,
            items: () => this.ampluaHandbook,
            onInput: () => {
                const amplua = this.ampluaHandbook.find((a) => a.id === this.amplua.value);
                if (amplua.name === "Спортсмен") {
                    this.category.show = true;
                    this.category.rules.push(Validation.required);
                    this.discipline.show = true;
                    this.discipline.rules.push(Validation.required);
                }
                else {
                    this.category.show = false;
                    this.category.rules.splice(0);
                    this.discipline.show = false;
                    this.discipline.rules.splice(0);
                }
                this.onRefresh();
            },
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.category = {
            ref: "category",
            type: "select",
            label: "Категория",
            value: null,
            items: () => this.event.categories.map((item) => {
                return {
                    ...item.category,
                    name: `${item.category.name} от ${item.category.ageCategory.ageFrom} до ${item.category.ageCategory.ageTo} лет`,
                    id: item.id,
                };
            }),
            onInput: () => {
                this.onRefresh();
            },
            showOnlyForSuperAuth: false,
            rules: [],
            show: false,
        };
        this.discipline = {
            ref: "discipline",
            type: "select",
            label: "Дисциплина",
            value: "",
            items: () => this.event.disciplines.map((item) => {
                let name = item.disciplineGroup.name;
                if (item.discipline !== null) {
                    name += ` | ${item.discipline.name}`;
                }
                return {
                    ...item.discipline,
                    name,
                    id: item.id,
                };
            }),
            onInput: () => {
                this.onRefresh();
            },
            showOnlyForSuperAuth: false,
            rules: [],
            show: false,
        };
        this.filters = [this.amplua, this.category, this.discipline];
    }
    onRefresh() {
        const category = this.category.value
            ? this.event.categories.find((item) => item.id === this.category.value).category
            : null;
        this.nonParticipants = this.getNonParticipants(category);
        this.clearSelected = !this.clearSelected;
    }
    get showDialog() {
        return this.value;
    }
    set showDialog(value) {
        this.$emit("input", value);
    }
    setSelectedUsers(users) {
        this.selectedUsers = users;
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get ampluaHandbook() {
        return [...this.$store.getters[HandbookModule.types.getters.AMPLUA]].reverse();
    }
    async addParticipants() {
        if (this.selectedUsers.length === 0) {
            return this.$notify({
                text: "Не выбран ни один участник",
            });
        }
        const hasError = Validation.validateWithView(this.filters, this.$refs);
        if (hasError)
            return this.$notify({
                type: "error",
                text: "Выберите категорию и дисциплину участников",
            });
        const participants = this.selectedUsers.map((user) => ({
            eventId: this.event.id,
            userId: user.id,
            ampluaId: parseInt(this.amplua.value),
            categoryId: this.category.value ? parseInt(this.category.value) : null,
            disciplineId: this.discipline.value ? parseInt(this.discipline.value) : null,
            applicantId: null,
        }));
        await this.$store.dispatch(EventModule.types.actions.ADD_PARTICIPANTS, {
            eventId: this.event.id,
            participants,
        });
        this.$notify({
            type: "success",
            title: "Участники успешно добавлены",
        });
        this.clearSelected = !this.clearSelected;
        this.$emit("eventChanged");
    }
    getNonParticipants(category = null) {
        let users = this.$store.getters[UsersModule.types.getters.USERS_BY_CATEGORIES].any.filter((user) => {
            // if (user.isStopped || moment().isAfter(moment(user.expiryDate))) return false
            const nonParticipant = this.event.participants.find((participant) => participant.userId === user.id) === undefined;
            if ((this.event.type.regionalStatus.id === 1 || this.event.type.regionalStatus.id === 4) && this.isSuperAdmin)
                return nonParticipant;
            if ((this.event.type.regionalStatusId === 1 || this.event.type.regionalStatusId === 4) &&
                this.admin &&
                this.admin.regionId === user.address.regionId)
                return nonParticipant;
            if (this.event.type.regionalStatus.id === 2) {
                const fromDistrict = user.address.region !== null && user.address.region.district.id === this.event.address.region.district.id;
                return nonParticipant && fromDistrict;
            }
            if (this.event.type.regionalStatus.id === 3) {
                const fromRegion = user.address.region !== null && user.address.region.id === this.event.address.region.id;
                return nonParticipant && fromRegion;
            }
        });
        if (category !== null) {
            users = users.filter((user) => {
                //const age = moment().diff(user.birthdate, "years", false)
                //const byAge = age >= category.ageCategory.ageFrom && age <= category.ageCategory.ageTo
                const byGender = category.gender === user.gender;
                //return byAge && byGender
                return byGender;
            });
        }
        return {
            nonParticipants: users,
        };
    }
    async mounted() {
        if (this.$store.getters[UsersModule.types.getters.USERS_BY_CATEGORIES].any.length === 0) {
            await this.$store.dispatch(UsersModule.types.actions.GET_USERS);
        }
        this.nonParticipants = this.getNonParticipants();
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], EventParticipants.prototype, "value", void 0);
__decorate([
    Prop({ type: Object })
], EventParticipants.prototype, "event", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], EventParticipants.prototype, "refreshNonParticipants", void 0);
__decorate([
    Watch("refreshNonParticipants")
], EventParticipants.prototype, "onRefresh", null);
EventParticipants = __decorate([
    Component({
        components: {
            ApproveDialog,
            UsersTable,
        },
    })
], EventParticipants);
export default EventParticipants;
